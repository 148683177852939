import React, { useState, useEffect, useRef } from "react"
import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"
import SEO from "../mainComponents/seo"
import HiringMeta from "../assets/HiringMeta.png"
import "../style/career.scss"
import Wrapper from "../mainComponents/Wrapper"
import { ourTeamData } from "../../data/ourTeamData"
import "../style/ourTeam.css"
import norNewman from "../images/our-team/nor-newman.png"
import salomeMikadze from "../images/our-team/salome-mikadze.png"
import coFoundersMain from "../images/our-team/co-founders-main.png"
import PMMain from "../images/our-team/PM-main.png"
import stanislavVdoviuk from "../images/our-team/stanislav-vdoviuk.png"
import mykhailoStefaniuk from "../images/our-team/mykhailo-stefaniuk.png"
import empty from "../images/our-team/empty.png"
import christinaSkiba from "../images/our-team/christina-skiba.png"
import svitlanaMidianko from "../images/our-team/svitlana-midianko.png"
import creativeMain from "../images/our-team/creative-main.png"
import hryhoriiKlochnyk from "../images/our-team/hryhorii-klochnyk.png"
import mariaDonchenko from "../images/our-team/maria-donchenko.png"
import veronikaPasternak from "../images/our-team/veronika-pasternak.png"
import serhiyDmytruk from "../images/our-team/serhiy-dmytruk.png"
import developersMain from "../images/our-team/developers-main.png"
import davidKagramanian from "../images/our-team/david-kagramanian.png"
import olehTurko from "../images/our-team/oleh-turko.png"
import vladHryha from "../images/our-team/vlad-hryha.png"
import danPetrenko from "../images/our-team/dan-petrenko.png"

const images = {
  "nor-newman": norNewman,
  "salome-mikadze": salomeMikadze,
  "co-founders-main": coFoundersMain,
  "PM-main": PMMain,
  "stanislav-vdoviuk": stanislavVdoviuk,
  "mykhailo-stefaniuk": mykhailoStefaniuk,
  "empty": empty,
  "christina-skiba": christinaSkiba,
  "svitlana-midianko": svitlanaMidianko,
  "creative-main": creativeMain,
  "hryhorii-klochnyk": hryhoriiKlochnyk,
  "maria-donchenko": mariaDonchenko,
  "veronika-pasternak": veronikaPasternak,
  "serhiy-dmytruk": serhiyDmytruk,
  "developers-main": developersMain,
  "david-kagramanian": davidKagramanian,
  "oleh-turko": olehTurko,
  "vlad-hryha": vladHryha,
  "dan-petrenko": danPetrenko,
}

const CareerPage = () => {
  const [activeTab, setActiveTab] = useState("co-founders")


  const tabData = ourTeamData[activeTab]
  return (
    <Wrapper>
      <Layout>
        <SEO
          title="Career"
          description={"Join Movadex team to revolutionise software development!"}
          img={HiringMeta}
        />

        <section className="our-team">
          <div className="container">
            <h1>We are a team that develops together</h1>

            <div style={{ "--team-color": tabData.color }} className="team-tabs-wrapper">
              <div className="tabs">
                {Object.entries(ourTeamData).map(([key, obj]) => (
                  <div
                    onClick={() => setActiveTab(key)}
                    className={`tab ${key === activeTab ? "active" : ""}`}
                  >
                    {obj.icon}
                  </div>
                ))}
              </div>
              <div className="tab-content">
                <div className="tab-left">
                  <div className="tab-description">
                    <span className="title">{tabData.title}</span>
                    <span className="description">{tabData.description}</span>
                  </div>

                  <img src={images[tabData["image-key"]]} alt="" />
                </div>
                <div className="tab-right">
                  {tabData.members.map(i => (
                    <div className="team-member">
                      <img src={images[i["image-key"]]} alt="" />
                      <span className="role">{i.position}</span>
                      <span className="name">{i.name}</span>
                      <span className="description">{i.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>

      <Footer>
        <FooterMail pageName="career" />
      </Footer>
    </Wrapper>
  )
}

export default CareerPage
